import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'

import {
  Button,
  Icon,
  Input,
  Popover,
  ServiceOrderFilters,
  TableV2 as Table,
  PaginationV2 as Pagination,
  EmptyState,
  LoaderV2 as Loader,
} from 'components'
import {
  ServiceOrderAggregatedQueryResponse,
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_TYPE_ENUM,
  SERVICE_ORDER_TYPE_EN_PT,
  ServiceOrderDriver,
} from 'services/serviceOrder'

import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'

import styles from './ServiceOrdersInValidation.module.scss'
import { useToggle, useUserInfo } from 'shared/hooks'
import { useOSFilter, handleStatusLabel } from 'domains/serviceOrders/utilities'

import { FormProvider, useForm } from 'react-hook-form'
import {
  ServiceOrderFiltersSchema,
  filterSchema,
} from 'components/ServiceOrderFilters/schemas/searchServiceOrderSchema'
import { joiResolver } from '@hookform/resolvers/joi'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { RichTooltip } from './../../components'
import {
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  flexRender,
} from '@tanstack/react-table'
import { maskedDateTime } from 'utilities/date'
import { buildPath, paths } from 'routes'

const columnHelper = createColumnHelper<ServiceOrderAggregatedQueryResponse>()

function ServiceOrdersValidation(): JSX.Element {
  const { userInfo } = useUserInfo()

  const filterComponent = useToggle()
  const navigate = useNavigate()

  const { filters, handleResetFilter } = useOSFilter()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleResetPagination = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '1',
    })
  }

  const form = useForm<ServiceOrderFiltersSchema>({
    resolver: joiResolver(filterSchema),
    mode: 'onChange',
    defaultValues: { applyAttendanceExclusivity: true, ...filters },
  })
  const { reset, watch, register } = form

  const { number, applyAttendanceExclusivity } = watch()

  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState({ data: [], totalElements: 0 })

  useEffect(() => {
    if (number) {
      reset({
        number,
        applyAttendanceExclusivity,
      })
    }
  }, [data])

  const fetchServiceOrders = useCallback(async () => {
    const {
      number,
      customer,
      patrimony,
      account,
      type,
      technicians,
      status,
      createdAtFrom,
      createdAtTo,
      scheduleDateFrom,
      scheduleDateTo,
      closedAtFrom,
      closedAtTo,
      tag,
      origin,
      reason,
      pauseReason,
      issue,
      resolution,
      applyAttendanceExclusivity,
    } = watch()

    if (number) {
      reset({
        number: watch('number'),
        applyAttendanceExclusivity: watch('applyAttendanceExclusivity'),
      })
    }

    const labels = [origin, reason, pauseReason, issue, resolution].reduce(
      (acc, array) => [...acc, ...(array || [])],
      [],
    )

    const page = Number(searchParams.get('page')) || 1
    const offset = (page - 1) * 15

    setIsFetching(true)

    try {
      const serviceOrders = await ServiceOrderDriver.queryPost({
        offset,
        recordsPerPage: 15,
        sort: 'updatedAt',
        sortType: 'ASC',
        status: [SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION],
        applyAttendanceExclusivity,
        coverageTypes: ['SERVICE_ORDER_ATTENDANT'],
        ...(userInfo && { userId: userInfo.id }),
        ...(number && { number }),
        ...(account && { accountIds: [account.id] }),
        ...(technicians && {
          technicianIds: technicians.map((technician) => technician.id),
        }),
        ...(createdAtFrom && { createdAtFrom }),
        ...(createdAtTo && { createdAtTo }),
        ...(scheduleDateFrom && { scheduleDateFrom }),
        ...(scheduleDateTo && { scheduleDateTo }),
        ...(closedAtFrom && { closedAtFrom }),
        ...(closedAtTo && { closedAtTo }),
        ...(labels?.length && { labelIds: labels.map((label) => label.id) }),
        ...(tag && { tagId: tag.id }),
        ...(type && { serviceOrderTypes: type }),
        ...(patrimony && !account ? { accountIds: [patrimony.accountId] } : {}),
        ...(customer && !account && !patrimony
          ? {
              accountIds: customer.accounts.length
                ? customer.accounts.map((account) => account.id)
                : [customer.id],
            }
          : {}),
        ...(!!status?.length && { status }),
        hasTechnician: watch('hasTechnician'),
        ...(!!watch('serviceType') && {
          serviceTypeId: watch('serviceType').id,
        }),
        ...(!!watch('accountTag') && {
          accountTagId: watch('accountTag').id,
        }),
      })

      setData(serviceOrders)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [userInfo, reset, watch, searchParams])

  const page = searchParams.get('page')
  const filter = searchParams.get('filter')

  useEffect(() => {
    if (page || filter) {
      fetchServiceOrders()
    }
  }, [page, filter])

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'id',
        header: 'Número',
        maxSize: 115,
        cell: (info) => (
          <div className={styles.statusBox}>
            <span>{info.getValue().number}</span>
            <Popover.Root>
              <Popover.Content>
                <RichTooltip serviceOrderId={info.getValue().id} />
              </Popover.Content>
              <Popover.Trigger className={styles[info.getValue().status]}>
                {handleStatusLabel(info.getValue().status)}
                <InfoIcon />
              </Popover.Trigger>
            </Popover.Root>
          </div>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: 'account',
        header: 'Conta',
        cell: (info) =>
          `${info.getValue().account?.code} - ${info.getValue().customer?.name}`,
      }),
      columnHelper.accessor('labels', {
        header: 'Motivo',
        cell: (info) => {
          const value = info.getValue()

          const reasonLabel = value?.find((label) => label.type === 'REASON')

          return reasonLabel?.description || ''
        },
      }),

      columnHelper.accessor('type', {
        header: 'Tipo',
        cell: (info) => (
          <>
            {
              SERVICE_ORDER_TYPE_EN_PT[
                info.getValue() as SERVICE_ORDER_TYPE_ENUM
              ]
            }
          </>
        ),
      }),
      columnHelper.accessor('technician', {
        header: 'Técnico',
        cell: (info) => info.getValue()?.name,
      }),
      columnHelper.accessor('updatedAt', {
        size: 95,
        header: 'Atualização',
        cell: (info) => <>{maskedDateTime(info.getValue())}</>,
      }),
      columnHelper.accessor((row) => row, {
        id: 'action',
        header: '',
        size: 50,
        cell: (info) => (
          <Icon
            name="success"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                buildPath({
                  path: paths.serviceOrder.details,
                  params: { serviceOrderId: info.getValue().id },
                }),
                {
                  state: { isValidate: true },
                },
              )
            }
          />
        ),
      }),
    ],
    [columnHelper],
  )

  const table = useReactTable({
    data: data?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }

    if (isFetching) {
      return 'loading'
    }

    if (isError) {
      return 'error'
    }

    return 'empty'
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>Ordens de serviço</span>
      <div className={styles.divider} />

      <FormProvider {...form}>
        <form
          className={styles.inputsWrapper}
          onSubmit={(event) => {
            event.preventDefault()
            handleResetPagination()

            handleResetFilter()
            reset({
              number: watch('number'),
              applyAttendanceExclusivity: watch('applyAttendanceExclusivity'),
            })

            fetchServiceOrders()
          }}
        >
          <div className={styles.innerWrapper}>
            <div className={styles.filtersButtons}>
              <Input
                id="number"
                placeholder="Digite o número identificador da OS"
                label="Número da OS"
                autoComplete="off"
                type="number"
                {...register('number')}
                disabled={filterComponent.isVisible}
              />
              <Button
                buttonTitle="Pesquisar"
                type="primary"
                disabled={filterComponent.isVisible}
                htmlType="submit"
              />
              <Button
                buttonTitle={'Adicionar filtros'}
                type="tertiary"
                icon={FiltersIcon}
                onClick={filterComponent.show}
              />
            </div>
          </div>
        </form>

        {
          {
            view: (
              <Table.Root>
                <Table.Header>
                  {table?.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.ResizableColumn
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: header.column.getSize() }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </Table.ResizableColumn>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Header>
                <Table.Body>
                  {table?.getRowModel().rows.map((row) => (
                    <Fragment key={row.id}>
                      <Table.Row>
                        {row.getVisibleCells().map((cell) => (
                          <Table.Cell
                            key={cell.id}
                            className={styles[cell.column.id]}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    </Fragment>
                  ))}
                </Table.Body>
              </Table.Root>
            ),
            empty: <EmptyState type="EmptyDataFromBFF" />,
            error: <EmptyState type="ErrorToLoadInfos" />,
            loading: (
              <div className={styles.loader}>
                <Loader />
              </div>
            ),
          }[handleRender(data?.data, isError, isFetching)]
        }
        <Pagination
          className={styles.pagination}
          totalElements={data?.totalElements}
        />

        <ServiceOrderFilters
          hiddenFilters={['schedule', 'finishedAt']}
          isVisible={filterComponent.isVisible}
          onClose={filterComponent.hide}
          onApplyFilters={() => {
            const { number } = watch()

            if (number) {
              form.setValue('number', undefined)
            }

            filterComponent.hide()
            handleResetPagination()
          }}
        />
      </FormProvider>
    </div>
  )
}

export default ServiceOrdersValidation
